<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<CartelSetting
			:user="user"

			class="flex-side mr-30"
		></CartelSetting>

		<div class="flex-1 bg-white pa-20 radius-20">

			<div class="under-line-dick pb-10 flex-row">
				<h3 class="flex-1">게시판 관리</h3>
			</div>
			<!-- 커버 -->
			<div class="mt-10 text-center">
				<div class="prl-20">
					<ul class="">
						<li class="mt-20">
							공지사항
						</li>
						<li class="mt-20">
							한줄응원
						</li>
					</ul>
				</div>

				<hr class="under-line mt-30">

				<div class="mt-10">
					<ul>
						<draggable
							v-model="items_board_config"
							@start="setAction"
							@end="endAction"
							handle=".handle"
						>
							<template
								v-for="(item, index) in items_board_config"
							>
								<li
									v-if="item.board_type_code != 'CA00700001' && item.board_type_code != 'CA00700002'"
									:key="'item_' + index"
									class="pa-5-20 flex-row justify-space-between items-center handle"
									:class="{ 'bg-gray-light': is_action === index}"
								>
									<span class="ptb-5">{{  item.board_name  }}</span>
									<div
										v-if="type == 'modify'"
									>
										<button
											@click="onModify(item)"
											class="btn-inline btn-primary-outline radius-20 pa-5-20 mr-10 size-px-12"
										>수정</button>
										<button
											@click="onConfirm(item)"
											class="btn-inline btn-primary-outline radius-20 pa-5-20 mr-10 size-px-12"
										>삭제</button>

										<v-icon class="cursor-move">mdi-reorder-horizontal</v-icon>										<!-- 순서이동 액션탭 -->
									</div>
								</li>
							</template>
						</draggable>
					</ul>
				</div>

				<hr class="under-line mt-30">

				<div
					class="mt-30 flex-row justify-space-between"
				>
					<button
						@click="on125"
						class="btn-inline btn-primary-outline radius-20 "
					><v-icon class="color-primary size-px-20">mdi-plus</v-icon>게시판 추가</button>


					<button
						class="btn-inline btn-primary radius-20 pa-10-20"
						@click="postBoardSort"
						:disabled="is_save"
					>저장</button>

				</div>
			</div>
		</div>

		<PopupConfirm
			v-if="is_confirm"
			@click="deleteBoard(true)"
			@cancel="is_confirm = false"
			class="text-center"
		>
			<template v-slot:title>게시판 삭제</template>
			<template
				v-slot:main-txt
			>게시판 삭제 시 등록된 글도 삭제가 됩니다.<br/>게시판을 삭제하시겠습니까?</template>
			<template
				v-slot:sub-txt
			>삭제를 원하시면 삭제버튼을 눌러주세요.</template>
		</PopupConfirm>

		<mafia125
			v-if="is_125"
			:user="user"
			:board="item_confirm"

			@cancel="is_125 = false"
			@click="off125"

		></mafia125>
	</div>
</template>

<script>
import draggable from 'vuedraggable'
import PopupConfirm from '@/view/Layout/PopupConfirm'
import CartelSetting from "@/view/Cartel/CartelSetting";
import Mafia125 from "@/view/Cartel/mafia125";
export default {
	name: 'mafia122'
	, props: ['user']
	, components: {Mafia125, CartelSetting, draggable, PopupConfirm}
	, data: function(){
		return {
			program: {
				name: '게시판 관리'
				, title: '게시판 관리'
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'
				, from: this.$route.params.from
				, bg_contents: 'bg-gray01'
			}
			, item_cartel: {}
			, items_board_config: []
			, type: 'modify'
			, is_action: ''
			, is_move: false
			, is_confirm: false
			, item_confirm: {}
			, item_search: {
				page_number: this.$language.base.page_number
				, pagerecnum: 30
			}
			, is_125: false
		}
	}
	, computed: {
		text_type_btn: function(){
			let t = ''
			switch (this.type){
				case 'view':
					t = '수정'
					break
				case 'modify':
					t = '완료'
					break
			}
			return t
		}
		, board_list: function(){
			let t = [];
			let i = 1;
			this.items_board_config.filter((item) => {
				if(item.board_type_code != 'CA00700001' && item.board_type_code != 'CA00700002'){
					t.push({
						board_number: item.board_number
						, board_name: item.board_name
						, sort_seqen: i
					})
					i++
				}
			})

			return t
		}
		, is_save: function(){
			let t = true
			if(this.is_move){
				t = false
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBoardConfig: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})
				if(result.success){
					let d = result.data.board_list
					this.items_board_config = []
					d.filter((item) => {
						if(item.board_type_code != 'CA00700001' && item.board_type_code != 'CA00700002') {
							this.items_board_config.push(item)
						}
					})
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, updateBoardConfig: async function(){
			//
		}
		, doType: function(){
			switch (this.type){
				case 'view':
					this.type = 'modify'
					this.$router.push('/cartel/mafia122/' + this.$route.params.idx + '/modify')
					break
				case 'modify':
					this.postBoardSort()
					break
			}
		}
		, goBack: function(){
			switch (this.type){
				case 'view':
					this.$emit('to', { name: 'mafia074', params: {idx: this.$route.params.idx}})
					break
				case 'modify':
					this.type = 'view'
					break
			}
		}
		, onModify: function(item){
			this.item_confirm = item
			this.is_125 = true
		}
		, onConfirm: function(item){
			this.item_confirm = item
			this.is_confirm = true
		}
		, deleteBoard: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_board_config_delete
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.item_confirm.board_number
					}
					, type: true
				})
				if(result.success){
					await this.getBoardConfig()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_confirm = false
			}
		}
		, setAction: function ($e){
			console.log('e', $e)
			this.is_action = $e.oldIndex
		}
		, endAction: function(){
			this.is_action = ''
			this.is_move = true
		}
		, postBoardSort: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_board_sort
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_list: this.board_list
					}
					, type: true
				})
				if(result.success){
					await this.getBoardConfig()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_confirm = false
				this.is_move = false
			}
		}
		, on125: function(){
			this.item_confirm = ''
			this.is_125 = true
		}
		, off125: function(){
			this.item_confirm = ''
			this.is_125 = false
			this.getBoardConfig()
		}
	}
	,created() {
		this.$bus.$emit('onLoad', this.program)
		this.getCartel()
		this.getBoardConfig()
	}
}
</script>

